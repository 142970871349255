import { ref, Ref } from 'vue'

const useFilterDatasets = (chartPayload: Ref<any>) => {
  const indexHidden = ref([])
  const reactiveChartData = ref()

  const getDataset = (existsPropertyDataset) => {
    if (!chartPayload.value) return []
    return existsPropertyDataset ? chartPayload.value.datasets : chartPayload.value
  }

  const updateDataset = (index) => {
    if (!chartPayload.value) return

    const existsPropertyDataset = chartPayload.value.datasets !== undefined
    const dataset = getDataset(existsPropertyDataset)

    if (indexHidden.value.includes(index)) {
      indexHidden.value = indexHidden.value.filter((index2) => index2 !== index)
    } else {
      indexHidden.value.push(index)
    }

    const filteredData = dataset.filter((_, i) => !indexHidden.value.includes(i))

    if (existsPropertyDataset) {
      reactiveChartData.value.datasets = filteredData
    } else {
      reactiveChartData.value = filteredData
    }
  }

  const updateGroupedDataset = (index, groups) => {
    if (!chartPayload.value) return

    if (indexHidden.value.includes(index)) {
      indexHidden.value = indexHidden.value.filter((index2) => index2 !== index)
    } else {
      indexHidden.value.push(index)
    }

    const existsPropertyDataset = chartPayload.value.datasets !== undefined
    const dataset = getDataset(existsPropertyDataset)
    const filteredData = dataset.filter((data) => {
      const groupsByIndex = indexHidden.value.map((hiddenGroup) => groups[hiddenGroup])
      return !groupsByIndex.includes(data.group)
    })

    if (existsPropertyDataset) {
      reactiveChartData.value.datasets = filteredData
    } else {
      reactiveChartData.value = filteredData
    }
  }

  return {
    indexHidden,
    reactiveChartData,
    updateDataset,
    updateGroupedDataset,
  }
}

export default useFilterDatasets
